'use client';

import { useFormState, useFormStatus } from 'react-dom';

type FormState = {
  error: string | null;
};

type ServerAction = (prevState: FormState, formData: FormData) => Promise<FormState>;

function SubmitButton() {
  const { pending } = useFormStatus();

  return (
    <button
      type="submit"
      disabled={pending}
      className="w-full px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
    >
      {pending ? 'Wird verarbeitet...' : 'Zugriff anfordern'}
    </button>
  );
}

export default function SiteLoginForm({ handleSubmit }: { handleSubmit: ServerAction }) {
  const initialState: FormState = { error: null };
  const [state, formAction] = useFormState(handleSubmit, initialState);

  return (
    <div className="p-6 bg-white rounded shadow-md">
      <h1 className="text-2xl font-bold mb-4">Websitezugriff</h1>
      <form action={formAction}>
        <div className="mb-4">
          <label htmlFor="secretKey" className="block text-sm font-medium text-gray-700">
            Zugriffsschlüssel
          </label>
          <input
            type="password"
            id="secretKey"
            name="secretKey"
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <SubmitButton />
      </form>
      {state.error && <p className="mt-4 text-red-500">{state.error}</p>}
    </div>
  );
}